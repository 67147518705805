htm,body{
    background-color: #121212;
    margin: 0;
    height: auto;
   color: #fff;
    box-sizing: border-box;
    overflow-x: hidden;
    
}   


@font-face {
    font-family: Kurayami;
    src: url('../fonts/Kurayami.woff2');
}
@font-face {
    font-family: blazma-regular;
    src: url('../fonts//Blazma-Regular.woff2');
}



::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #333; 
}


::-webkit-scrollbar-thumb {
  background: #666; 
}


::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-track {
  background: #333; 
}

::-moz-scrollbar-thumb {
  background: #666; 
}

a{
    text-decoration: none !important;
    color: inherit !important;
    z-index: inherit;
}


button{
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 0.8em;
    font-weight: bold !important;
}
p{
    margin: 0.5%;
}
header{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    height: 100px;
    color: #fcfcfc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 1.1em;
    border-bottom: 2px solid #fcfcfc3f;
}

header nav a{
  display: flex;
  flex-direction: row;
  align-items: center;

}

nav img{
  width: 20%;
  height: 100%;
}

h1{
    margin: 0;
    font-family:Kurayami;
    font-size: 3em;
}

header nav{
    width: 30%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    color: #fcfcfcc2;
    font-family: blazma-regular;
}

footer nav{
  width: 45%;
  height: 50%;
  display: flex;
  font-size: 1.3em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  color: #fcfcfcc2;
  font-family: blazma-regular;
}

nav p{
    cursor: pointer;
}
.utf-bookmark{
  display: block;
  width: 5%;
  height: 100%;
  font-size: 1.3em;
  margin-right: 5%;
}

.utf-bookmark_anime-page{
  width: 20%;
  font-size: 2em !important;
}
.bookmark p{
  width: 80%;
}
.not-found{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  font-size: 2em;
  justify-content: center;
  align-items: center;
}
.search-form{
    width: 30%;
    z-index: 99999;
    height: 50%;
    position: relative;
}
.search-form img{
    position: absolute;
    width: 5%;
    height: 5%;
}
.search-form input{
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #ffffff27;
    border: 2px solid #ffffff27;
    border-radius: 10px;
    color: #fcfcfcc2;
}
.search-form input:focus{
    outline: none;
}
.search-form input::placeholder{
    color: #fcfcfcc2;
}

.buttons{
    width: 20%;
    height: 50%;
    display: flex;
    gap: 5%;
    font-size: 0.9em;
    flex-direction: row;
}
.buttons img{
    width: 25%;
    margin-right: 5%;
    height:40px;
}

.button{
    cursor: pointer;
    width: auto;
    height: 100%;
    padding: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.button p{
    margin: 0;
    padding: 0;
}
.sign-in{
    color: #fff;
    background-color: #252425;
}
.reg{
  overflow: hidden;
    color: #000;
    background-color: #ffff; 
}



.Main_Content_Top{
  margin-top: 15%;
  margin-bottom: 5% ;
  width: 100%;
  height: 700px;

}

.Main_Content_Top h2{
  text-transform: uppercase;
  background: linear-gradient(45deg, #ff1e00 0%, #ebd510 50%, rgb(235, 221, 26) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size:70px;
  -webkit-text-fill-color: transparent;
}






.banner_wrapper{
    width: 100%;
    overflow: hidden;
    height: 750px;
}

.banner-slider{
  display: flex;
}
.announcement_banner{
    
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    z-index: 1;
    height: 750px;
}


.announcement_banner video{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }


.overlay{
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.438); 
}

.overlay-images {
  position: absolute;
  bottom: 0;
  z-index: 99;
  left: 0;
  width: 100%;
  height: 30%;
  border-radius: 35px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}


  .announcment_content{
    position: absolute;
    z-index: 999;
    top: 25%;
    margin-left: 10%;
    width: 50%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
.special-item{
  height: 300px !important;
}
  .announcement_title{
    font-size: 3.5em;
    font-weight: bold;
    height: auto;
  }

  .announcement_text{
    font-size: 1.3em;
    width: 80%;
    height: 40%;
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  .announcement_buttons{
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    gap: 5%;
  }

  .announcement_buttons button{
    width:20%;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1%;
    height: 100%;
  }

  .announcement_buttons button img{
    width: 40%;
    height: 100%;
  }

  .about-btn{
    width: 20%;
    height: 100%;
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1em;
    background-color: orangered;
  }

 

.bookmark{
    background-color: #252425;

}






.Main_Content{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    background-color: #121212;
}

.form-block{
box-sizing: border-box;
padding: 2%;
top: 100%;
transition: all 0.4s ease-in-out;
opacity: 0;
z-index: 0;
position: absolute;
right: 0;
width: 100%;
overflow-y:auto;
border-radius: 25px;
height: 500px;
background-color: #252425;
}

.search-showed{
  opacity: 1;
  z-index: 999;
}


.form-block-item{
  cursor: pointer;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 2%;
  transition: all 0.2s ease-in-out;
}
.form-block-item:hover{
  background-color: #3b3b3b;
}

.form-block-item-image{
  position: inherit !important;
  background-color: gainsboro;
  width: 20% !important;
  height: 100% !important;
  border-radius: 15px;
}
.form-block-item-info{
  width: 70%;
  height: 100%;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  
}
.form-block-item-info h2{

  margin: 0; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  width: 100%;
}
.form-block-item-info p{
  width: 100%;
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.Main_Content_list{
    width: 100%;
    height: auto !important;
    z-index: 99;

}
.recomendation{
    position: relative;
    top: -180px;
    height: auto !important;
}
.Main_Content_list h2{
    font-size: 2em;

}


.Main_Content_items{
    width: 100%;
    height: 350px;
    user-select: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-around !important;
    transition: all 0.1s ease;
}
.swiper-button-next,
.swiper-button-prev {
  color: orangered; 

  height: 30px;
  width: 10px;
}


.popular-item{
  height: 300px ;
}

.trend-item{
  width: 23% !important;
}
.Main_Content_item:hover{
  transition: all 0.1s ease;
  border: 5px solid orangered;
}
.Main_Content_item{

    position: relative;
    height: 100% ;
    flex-shrink: 0;
    user-select: none !important;
    border-radius: 35px;
    cursor: pointer;
}

.upper-score{
  display: block;
  z-index: 999;
  background-color: #252425d2;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  flex-direction: row-reverse;
  position: absolute;
  height: 10%;
  overflow: hidden;
  border-end-end-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 40%;
  left: 30%;
  top: 0;
}
.upper-score p{
  font-weight: bold;
  width: 50%;
  opacity: 0.8;
  font-size: 1.1em;

}
.upper-score img{
  width: 50% !important;
  height: 100%;
}

.Main_Content_item img{
    position: absolute;
    border-radius: 35px;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
}




.Main_Content_item_text{
    display: block;
    position: absolute;
    bottom: 5%;
    z-index: 100;
    left: 0;
    font-size: 0.9em;
    box-sizing: border-box;
    padding-left: 5%;
    height: 100px;
    width: 100%;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    transition: background-color 0.3s ease; 
    display: flex;
    justify-content: end;
    flex-direction: column;
}
.Main_Content_item_text h3{
  font-size: 1.1em;
  margin-bottom: 0;
  overflow: hidden;
  box-sizing: border-box;
  text-align: start ;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-shadow: 0 0 3px #ff0000, 0 0 5px #ff6600;
}

.Main_Content_item_info{
    width: 100%;

    color: #fcfcfcc2;
    display: flex;
    flex-direction: row;
   
}



.Main_Content_Collections{
    width: 100%;
    height: 400px;
}
.Main_Content_Collections h2{
  font-size: 2em;
  margin-top: 0;
}
.Main_Content_Collections__items{
    word-wrap: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.Main_Content_Collections__item{
    cursor: pointer;
  padding-top: 1%;
    border-radius: 35px;
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: all 0.1s ease;
    width: 32%;
    background-color: #252425;
}
.Main_Content_Collections__item:hover{
  transition: all 0.1s ease;
  border: 5px solid orangered;
}
.Main_Content_Collections__item h3{
    text-align: center;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
    font-size: 1.7em;
}
.first-card{
    position: absolute;
    bottom: -10%;
    left: 10%;
    border-radius: 25px;
    width: 35%;
    transform: rotate(-10deg);
    height: 70%;
    border: 5px solid #161616;
}


.second-card{
    position: absolute;
    bottom: -15%;
    left: 25%;
    z-index: 99;
    border-radius: 25px;
    width: 35%;
    transform: rotate(-1deg);
    height: 70%;
    border: 5px solid #161616;
    
}

.third-card{
    position: absolute;
    bottom: -25%;
    left: 50%;
    z-index: 100;
    border-radius: 25px;
    width: 35%;
    transform: rotate(10deg);
    height: 70%;
    border: 5px solid #161616;
  
}
.first-card img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}
.second-card img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
}
.third-card img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.trending_img{
  height: 100% !important;
  width: 23%;
 
}
.trending_section{
  float: right;
  width: 70%;
}


.trending{
    margin-top: 10%;
    height: 500px !important;
   margin-bottom: 10%;

}



.popular{
    margin-top: 5%;
    height: auto;
}

.popular-items {
    height: auto !important;
    flex-wrap: wrap;
    justify-content: inherit;
    gap: 2%;
}

.popular-item{
    margin-bottom: 2%;
    height: 300px;
    width: 16%;
    cursor: pointer;
}






footer{
  margin-top: 2%;
    height: 100px;
    width: 100%;
    border-top: 2px solid #fcfcfc3f;
    background-color: #121212;
    box-sizing: border-box;
    padding-left: 7%;
    padding-right: 7%;
}

.social{

    float: right;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.social img{
    width: 30%;
    height: 100%;
}










.catalog_title {
    width: 100%;
    height: 100px;
    margin-top: 10%;
    margin-bottom: 1%;
}
.catalog_wrapper{
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    height: auto;
    width: 100%;
}

.catalog_title h2{
    height: 10%;
    float: left;
    margin: 0;
    font-size: 3.5em ;
}



.sorting{
    height: auto;
    width: 10%;
    cursor: pointer;
    float: right;  
    transition: rotate 0.5s ease;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.1em;
    color: #fcfcfcc2;
    position: relative;
}
.arrow-icon{
    cursor: pointer;
    height: 100%;
    width: 40%;
    transition: all 0.5s ease;
}

.sorting-content{
    z-index: 9999;
    padding: 3%;
    position: absolute;
    width: 100%;
    height: auto;
    background-color: #252425;
    top: 100%;
    border-radius: 15px;
}
.sorting-content img{
    width: 20%;
    height: 100%;
}

.arrow-active{
  rotate: 180deg;  
}
.sorting-content-item{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    justify-content: space-around;
 }
 .sorting-content-item:hover{
    color: #fff;
 
 }
.catalog_main{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.catalog_sidebar{
    width: 15%;
    height: auto;
    box-sizing: border-box;
    padding-left: 1%;
    padding-right: 1%;
}

.catalog_item{
    margin-bottom: 20%;
}


.catalog_item__title{
position: relative;
width: 100%;
cursor: pointer;
height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}



.premium-text p{
  text-transform: uppercase;
  background: linear-gradient(45deg, #5241f1 0%, #f56b1c 50%, rgb(255, 51, 15) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size:50px;
  -webkit-text-fill-color: transparent;
}


.catalog_item__title span{
  position: absolute;
  bottom: -30%;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #fcfcfc2f;
}
.catalog_item__title p{
    font-size: 1.5em;
}

.catalog_item__content{
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    display: none;
    transition: all 0.3s ease; 
}

.year-selection{
    z-index: 999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.year-selection input{
    background: none;
    border: 2px solid #fcfcfc3f;;
    width: 40%;
    height: 60px;
    color: #fff;
    font-size: 1.1em;
    border-radius: 20px;
}

 .setting-item img{
  width:5%;
  height: 80%;
}

.open {
    display: block;
  }

  .main-selection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

 label{
    font-size: 20px;
    color: #fcfcfcc2;
    display: flex;
    flex-direction: row;
    gap: 5%;
    margin-bottom: 5%;
    cursor: pointer;

 }

 label input {
    display: none;
  }
  label span {
    height: 15px;
    width: 15px;
    display: inline-block;
    position: relative;
    border: 2px solid #fcfcfc3f;
    background-color:none;
    border-radius:2px;
    padding:3px;
  }
  [type=checkbox]:checked + span:before {
    content: '\2714';
    position: absolute;
    width: 23px;
    height: 24px;
    border-radius:2px;
    top: -1px;
    left: -1px;
    font-size:20px;
    background-color: #fff;
    color:rgb(0, 0, 0);
  }

.item-catalog{
  width: 16%;
    height: 250px !important;
    margin-bottom: 2%;
}

  .catalog_content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
    width: 85%;
    height: auto;
  }





  .News_wrapper{
    width: 100%;
    height: auto;
  }
  
  .News_wrapper__banner{
    position: relative;
    width: 100%;
    height: 600px;
  }
  
  .News_wrapper__banner img{
    width: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    height: 100%;
  }
  .dark-bg{
   
    transition: all 1s ease;
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    background-color: #00000033;
  }

.News_wrapper__banner_text{
    z-index: 999;
    width: 30%;
    height: 40%;
    position: absolute;
    top: 30%;
    left: 10%;
}
  .News_wrapper__banner_text h2{
    font-size: 2.5em;
    margin-top: 0;
  }
  .News_wrapper__banner_text span{
    color: orangered;
    font-weight: bold;
    font-size: 1.5em;
  }

  .News_wrapper__banner_text button{
    width: 25%;
    font-size: 1em;
    cursor: pointer;
    height:50px;
    background-color: #fff;
    color: #000000;
  }

  .News_wrapper__main{
    z-index: 9999;
    width: 100%;
    height: auto;
    position: relative;
    box-sizing: border-box;
    padding-left: 10%;
    padding-right: 10%;
  }
  .News_wrapper__content{
    position: relative;
    z-index: 9999;
    width: 90%;
    margin: auto;
    height: auto;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .News_wrapper__content_item {
    cursor: pointer; 
    position: relative;
    border-radius: 35px;
    width: 30%;
    margin-top: 5%;
    transform: translateY(-30%);
    height: 400px;

  }
  .News_wrapper__content_item:nth-child(1){
    width: 50%;
  }


  .News_wrapper__content_item:nth-child(2){
    width: 45%;
  }


  .News_wrapper__content_item:nth-child(3){
    width: 30%;
  }

  .News_wrapper__content_item:nth-child(4){
    width: 30%;
  }
  .News_wrapper__content_item:nth-child(6){
    width: 50%;
  }
  .News_wrapper__content_item:nth-child(7){
    width: 45%;
  }
  

  .News_wrapper__content_item img{
    position: absolute;
    width: 100%;
    border-radius: 35px;
    height: 100%;
  }

  .News_wrapper__content_text{
    overflow: hidden;
    position: absolute;
    bottom: 0;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5%;
    height: 40%;
    background-image: linear-gradient(to bottom, #ffffff00 0%, #00000081 40%, #000000 100%);
  }

  .News_wrapper__content_text span{
    color: orangered;
    font-weight: bold;
  }
  .News_wrapper__content_text p{
    font-size: 1.5em;
  }

.Rate_wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.Main_Content_Top__rating{
  width: 60%;
  height: 100%;
}
.Main_Content_Top__rating h2{
  width: 100%;
  height: 100px;
  margin: 0;
}
.rating_content{
  width: 80%;
  height: 600px;
  float: left;
  display: flex;
  justify-content: end;
  flex-direction: column;
  gap: 5%;
}
.rate-item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  height: 10%;
}
.rate-line{
  display: block;
  position: relative;
  width:50%;
  border-radius: 50px;
  background-color: #000;
  height: 30%;
  margin-right: 2%;
}
.rate-status{
  position: absolute;
  left: 0;
  border-radius: 50px;
  background-color: red;
  height: 100%;
}
.rate-position{
  font-size: 1.6em;
}
.rate-name{
  width: 50%;
  opacity: 0.9;
  margin-left: 5%;
  font-style: italic;
}
.rating_content__title{
  font-size: 2em;
}
.rate-number{
  font-size: 1.2em;
}

.rating_firstPlace{
  width: 40%;
  height: 100%;
}
.rating_firstPlace .title-tags{
  justify-content: flex-end;
  float: right
}
.rating_firstPlace h2{
  width: 100%;
  height: 15%;
  margin-top: 0;
}
.firstPlace_image{
  width: 80%;
  border-radius: 15px;
  float: right;
  height: 70%;
}
.firstPlace_name{
  height: 10%;
  font-size: 2.5em;
  float: right;
}



  .Collection_wrapper{
    z-index: 9999;
    width: 100%;
    height: auto;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    margin-top: 8%;
  }

  .Collection_wrapper__title{
    width: 80%;
    margin: auto;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Collection_wrapper__title h2{
    font-size: 2.5em;
  }
  
  .Collection_wrapper__title button{
    cursor: pointer;
    background-color: #fff;
    height: 60px;
    font-size: 1.1em;
    width: 15%;
    color: #000;
  }

  .Collection_wrapper__main{
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    margin: auto;
    width: 90%;
    height: auto;
    margin-bottom: 5%;
  }

  .collection_item{
    cursor: pointer;
    margin-bottom: 2%;
    height: 350px;
    width: 30% !important;
  }

  .best-collection{
    height: 350px;
    cursor: pointer;
    margin-bottom: 1%;
  }
  

  
  .page_video-banner{
    position: relative;
    width: 100%;
    height: 350px;
  }
  .page_video-banner video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.page_video-banner button{
  z-index: 990;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 8%;
  display: flex;
  gap: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #fff;
  color: #000;
}

.page_video-banner button:last-child{
  z-index: 990;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 4%;
  display: flex;
  gap: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #fff;
  color: #000;
}

.page_video-banner button:last-child img{
  width: 100%;
}
.page_video-banner button:last-child svg{
  width: 100%;
}


.page_video-banner button img{
  width: 20%;
  height: 100%;
}

.page_video-banner button svg{
  width: 20%;
  height: 100%;
}

.anime_main-info{
  position: relative;
  width: 85%;
  height: auto;
  margin: auto;
  display: flex;
  gap: 5%;
  flex-direction: row;
  z-index: 100;

}

.anime_main-info_image{
  float: left;
  z-index: 999;
  top: -20%;
  border-radius: 35px;
  width: 23%;
  height: 500px;
  transform: translateY(-35%);
}
.anime_main-info_image img{
  border-radius: 35px;
  width: 100%;
  height: 100%;
}

.anime_main-info_title{
  width: 70%;
  height: 300px;
}
.anime_main-info_title h2{
  font-size: 3.5em;
  margin-bottom: 0;
}
.anime_main-info_title h3{
  
  color: #cececec0;
}

.rating{
  display: block;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
  display: flex;
  flex-direction: row;
  gap: 2%;
  font-size: 1.5em;
}

.rating-webSite{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 10%;
  height: 100%;
}
.rating-webSite img{
  height: 100%;
  width: 50%;
}

.rating-webSite svg{
  height: 100%;
  width: 50%;
}


.anime_main-info_title__buttons{
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-top: 6%;
  width: 100%;
  height: 20%;
  position: relative;
}

.anime_main-info_title__buttons button {
  position: relative;
  padding: 1%;
  cursor: pointer;
  background-color: #252425;
  width: auto;
  width: 20%;
  font-size: 1em;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: 5%;
  height: 100%;
}

.anime_main-info_title__buttons button:first-child{
  background-color: orangered ;
}

.anime_main-info_title__buttons button span{
  font-size: 1em;
  font-weight: lighter;
}
.anime_main-info_title__buttons button svg{
  width: 15%;
}
.anime_main-info_title__buttons button img{
  width: 10%;
}

.anime_main-info_title__buttons button:last-child{
 position: absolute;
 width: 25%;
 font-size: 1em;
 display: flex;
 align-items: center;
 justify-content: center;
 right: 0;
}

.anime_main-additional{
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-left: 7%;
  padding-right: 7%;
}

.anime_main-additional__panel{
  width: 100%;
  height: auto;
}



.tabs-container {
  width: 100%;
  
}

.tabs {
  margin-top: 2%;
  box-sizing: border-box;
  font-size: 1.3em;
  display: flex;
  width: 80%;
  position: relative;
 
}
.active{
  color: #fff !important;
}
.tab {
  flex: 1;
  color: #fcfcfcc2;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 2px solid rgba(241, 241, 241, 0.164);
}

.tab:hover{
  background-color: #444444;
  
}
.user-items{
  justify-content: inherit !important;
}
.video-player video::-webkit-media-controls-timeline-progress {
  background-color: orangered !important;
}


.video-player video::-moz-progress-bar {
  background-color: orangered !important;
}
.tab-content {
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  min-height: 500px;
}
.tab-content h2{
  font-size: 1.7em;
}
.indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #fcfcfcc2;
  transition: left 0.3s, width 0.3s;
}


.Details{
  width: 15%;
  height: auto;

}

.Description{
  width: 75%;
  height: auto;
}

.Description p{
  font-size: 1.2em;
  line-height: 1.6em;
}

.content-review{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.user-collection_item__dlt-btn{
  position: absolute;
  width: 15%;
  height: 15%;
  right: 3%;
  border-radius: 10px;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  top: 3%;
  background-color: rgb(255, 255, 255);

}
.user-collection_item__dlt-btn img{
  transition: all 0.2s ease;
  width: 60%;
  height: 60%;
  z-index: 9999;
}
.user-collection_item__dlt-btn img:hover{
  transform: scale(1.2);
}

.details-info{
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 100%;
  margin-bottom: 5%;
}

.details-info p:first-child{
  width: 50%;
  color: #fcfcfcc2;
}

.details-info p:last-child{
  width: 50%;
  height: auto;
  word-wrap: break-word;
  color: #fcfcfc;
}


.relations{
  width: 100%;
  height: auto;
}

.relations_main{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.relations-item{
  height: 250px;
  margin-bottom: 1%;
}

.characters{
  width: 100%;
}

.characters-main{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5%;

}
.characters-item{
  height: 250px;
  margin-bottom: 1%;
}


.anime-gallery {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 5%;
  padding-left: 7%;
  padding-right: 7%;
  width: 100%;
  height: 300px;
  transform: translateY(-20%);
}
.anime-characters{
  font-size: 1.5em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anime-gallery h2 {
  width: 100%;
  height: 10%;
  font-size: 2.7em;
}

.anime-gallery_main {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 2%;
  overflow: hidden;
  z-index: 1;
}



.carousel-slide {
  cursor: pointer;
  flex: 0 0 auto;
  user-select: none;
  width: 300px;
  height: 100%;
  background-color: royalblue;
}



.carousel-button{
  cursor: pointer;
  background: none;
  font-size: 3.5em;
  z-index: 999999;
}
.sample{
  transition: all 2s ease;
  width: 100%;
  height: 600px;
}



.NewsPage{
  width: 100%;
  height: auto;
}
.NewsPage_banner{
  width: 100%;
  height: 400px;
  position: relative;
  
}
.NewsPage_banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.NewsPage_main{
  width: 50%;
  box-sizing: border-box;
 margin: auto;
  height: auto;
}
.NewsPage_main h2{
  font-size: 3.5em;
  width: 100%;
  text-align: center;
  margin: auto;
}

.NewsPage_main_secion{
  width: 100%;
  height: auto;
  margin-top: 5%;
}
.NewsPage_main_secion img{
  display: block;
  border-radius: 25px;
  margin: auto;
  width: 100%;
  height: 450px;
  
}
.NewsPage_main_secion p{
  font-size: 1.3em;
  line-height: 2em;
}
.NewsPage_main_title{
  position: relative;
  width: 100%;
  height: 10%;

}
.news-timeStamp{
  right: -25%;
  top: 0;
  position: absolute;
  display: block;
  width: 200px;
  display: flex;
  font-size: 1.5em;
  flex-direction: row;
  gap: 5%;
  color: #fcfcfcc2;
  align-items: center;
  height: 100px;
}

.news-timeStamp img{
  width: 20%;
  height: 100%;
}



.Collection_main{
  transition: all 0.5s ease;
  top: 0;
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.collection-new{
  transition: all 0.5s ease;
  top: 0;
  opacity: 0;
  z-index: -1;
  background-color: #0000009d;
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.collection-new-main{
  position: relative;
  background-color: #252425;
  width: 30%;
  box-sizing: border-box;
  padding: 2%;
  height: 70%;
  overflow-y: auto;
  border-radius: 20px;
}

.collection-new-main h2{
  margin-top: 0;
  font-size: 2.5em;
}
.collection-new-main h3{
  color: #c2c2c29c;
}
.collection-new-main input{
  height: 150px;
  border: 2px solid #c2c2c23d;
  border-radius: 10px;
  color: #dad8d89c;
}

.collection-new-title{
  width: 100%;
  height: auto;

}


.collection-new-main textarea {
  height: 150px;
  width: 100%;
  border: 2px solid #c2c2c23d;
  border-radius: 10px;
  color: #dad8d89c;
  font-size: 1.2em;
  background-color: transparent;
}

.collection-new-sumbit{
  width: 50%;
  height: 60px;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 1.1em;
  color: #000;
}
.active-collection_Type{
  background-color: orangered;
}

.collection-new-title input{
  height: 50px;
  width: 100%;
  color: #ffffffc4;
  font-size: 1.5em;
  font-weight: bold;
}

.collection-new-type{
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  height: 100px;
}

.collection_Type_menu{
  width: 100%;
  border-radius: 10px;

}
.collection_Type_menu_item{
  width: 100%;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  display: flex;
  border-radius: 10px;
  color: #ffffffc4;
  font-size: 1.1em;
  flex-direction: row;
  gap: 5%;
  border: 2px solid #c2c2c23d;
  margin-bottom: 5%;
  align-items: center;
}

.collection_Type_menu_item img{
  width: 20%;
  height: 100%;
}


.CollectionPage_bg{
  top: 0;
  transition: all 0.5s ease;
  position: absolute;
  width: 100vw;
  z-index: 9;
  height: 100vh;
  overflow: hidden;
  background-color: #000000a1;
}


.CollectionPage_main{
  box-sizing: border-box;
  padding: 2%;
  position: absolute;
  top: 5%;
  left: 30%;
  margin: auto;
  overflow-y: auto;
  height: 90vh;
  width: 40vw;
  z-index: 999;
  background-color: #252425;
  border-radius: 35px;
}



.CollectionPage_main h2:nth-child(2n){
  color: #fff;
  font-size: 2em;
  margin-top: 0;
}
.CollectionPage_main h2{
  color: orangered;
}


.collection-showed{
  opacity: 1;
  z-index: 99999;
}

.CollectionPage_main_item{
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  margin-bottom: 10%;
}

.CollectionPage_main_item:hover{
  background-color: #00000050;
}
.CollectionPage_main_item-number{
  width: 20%;
  height: 100%;
  font-size: 5.5em;
}

.collection-Text{
  width: 100%;
  height: auto;
  user-select: none;
  font-style: italic;
  box-sizing: border-box;
  padding: 2%;
  color: #afafafea;
  margin-top: 5%;
  margin-bottom: 5%;
  border-bottom: 1px solid #afafafea;
  border-top: 1px solid #afafafea;
}

.CollectionPage_main_item-main{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;  
}
.CollectionPage_main_item-main h2{
  width: 100%;
  margin: 0;

}
.CollectionPage_main_item-main p{
  height: 90%;
  font-size: 0.9em;
  color: #fcfcfcc2;
  overflow: hidden;
  text-overflow: ellipsis;
 
}
.CollectionPage_main_item-rate{
  font-size: 4.5em;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

.CollectionPage_main_item-rate img{
  width:100%;
  height: 80%;
  border-radius: 20px;
}
.collection-rate{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content:flex-end;
  flex-direction: row;
  gap: 5%;
}

.collection-rate img{
  width: 20%;
  height: 100%;
}
.collection-rate p{
  font-size: 25px;
}


.close-btn{
  position: absolute;
  width: 50px;
  top: 2%;
  cursor: pointer;
  right: 5%;
  height: 50px;
}

.recomendation-btn{
  top: 50%;
  font-size: 15px;
}

.recomendation-btn button{
  background-color: #fff;

  color: #000;
}


.carousel-slide img{
  width: 100%;
  height: 100%;
}

.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image-container {
  position: relative;
  margin: auto;
  width: 80%;
  height: 90%;
}

.fullscreen-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.close-fullscreen-button {
  background-color:#474747c2;
  position: absolute;
  top: 10px;
  width: 10% !important;
  height: 10% !important;
  text-shadow: 3px 2px #000;
  right: 10px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 80px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.close-fullscreen-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.main-slider{
  position: absolute !important;
}

.title-tags{
  width: 100%;
  height: 30px;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
}
.title-tags span{
  width: 8%;
  color: rgb(255, 106, 51);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid orangered;
  height: 100%;
  background-color: rgb(37, 10, 0);
}
.title-tags p{
  margin: 0;
  color: greenyellow;
  font-size: 1.2em;
}

.carousel-buttons{
  position: absolute;
  gap: 5%;
  width: 100%;
  top: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.staff-name{
  font-size:0.9em !important;
}

.staff-role{
  font-size: 0.8em !important;
}



.trailer-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.4s ease-in;
  height: 100%;
  background-color: #000000c0;
}
.close-trailer{
  cursor: pointer;
  position: absolute;
  z-index: 999;
  width: 10%;
  height: 10%;
  top: 5%;
  right: 5%;
}
.controls{
  display: flex;
  position: absolute;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #0000005d;
 
}

.hidden{
  display: none;
}

.video-player {
  margin: auto;
  width: 100%;
  background-color: #000;
  height: 90%;
  position: relative;
  overflow: hidden;
}

.Main_Content_Upcoming{
  width: 100%;
  height: auto;
}

.Main_Content_Upcoming_wrapper{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
}


.title_number{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.5em;
  font-family: blazma-regular;
  padding: 2%;
  background-color: #000;
}
.UpcomingTitle_item{
  position: relative;
  width: 15%;
  height: 450px;
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}
.UpcomingTitle_item img{
  width: 100%;
  height: 70%;
}
.UpcomingTitle_type{
  color: greenyellow;
   font-weight: bold;
}
.UpcomingTitle_date{
  opacity: 0.8;
}
.UpcomingTitle_Info{
  height: 30%;
  width:100% ;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.UpcomingTitle_name{
  height: 40%;
  width: 100%;
  font-size: 1em;

}
.UpcomingTitle_additional{
  display: flex;
  flex-direction: row;
gap: 5%;
  width: 100%;
  height: 60%;
}


.video {
  width: 100%;
}

.player-controls {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: flex-start;
  bottom: 0;
  width: 100%;
  height: 100px;
  transform: translateY(100%) translateY(-5px);
  transition: 0.3s;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.6);
}
.sound-tool{
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.hidden-control{
  transform: translateY(100%) !important;
}



.sound-tool svg{
  width: 15%;
  height: 100%;
}

.video-player:hover .player-controls {
  transform: translateY(25%);
}

.play-button {
  cursor: pointer;
  width: 5%;
  height: 60%;
  border: none;
  font-size: 1.5em;
  background: black;
  color: white;
  padding: 10px;
}

.play-button:focus {
  outline: none;
}




.volume{
  width: 70%;
}
.video-progress {
  position: relative;
  display: flex;
  width: 70%;
  height: 5px;
  transition: 0.3s;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

input[type="range"] {  
  appearance: red;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}
.video-progress-filled {
  width: 0;
  background: orangered;
}

.video-player:hover .video-progress {
  height: 20px;
}

.video-tools{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 20%;
  height: 100%;
}
input {
  --c: orange; 
  --l: 8px; 
  --h: 30px; 
  --w: 10px; 
  
  width: 400px;
  height: var(--h); 
  --_c: color-mix(in srgb, var(--c), #000 var(--p,0%));
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  overflow: hidden;
}
input:focus-visible,
input:hover{
  --p: 25%;
}


input[type="range" i]::-webkit-slider-thumb{
  height: var(--h);
  width: var(--w);
  background: var(--_c);
  border-image: linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 100vw;
  -webkit-appearance: none;
  appearance: none;
  transition: .3s;
}

input[type="range"]::-moz-range-thumb {
  height: var(--h);
  width: var(--w);
  background: var(--_c);
  border-image: linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 100vw;
  -webkit-appearance: none;
  appearance: none;
  transition: .3s;
}
@supports not (color: color-mix(in srgb,red,red)) {
  input {
    --_c: var(--c);
  }
}



.turned{
  display: block;
}
.fullscreen-button{
  background: transparent !important;
  font-size: 3em !important;
  cursor: pointer;
  text-align: center;
  width: 20%;
}









.center {
  height: 200px;
  display: flex;
  position: absolute;
  left: 10%;
  top: 10%;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: none;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, orangered, #df1212);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}




.registration-form{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000a2;
}
.login-form{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000a2;
}
.suggest-block{
  width: 100%;
  height: 10%;
  font-size: 1.1em;
  text-align: center;
}
.suggest-block span{
  color: greenyellow;
  cursor: pointer;
}
.deactivated-form{
  z-index: -1 !important;
  opacity: 0;
}



.custom-file-upload {
  background-color: orangered; 
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px; 
}

.registration-main{
  background-color: #1d1d1d;
  width: 40%;
  position: relative;
  height: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center ;
  box-sizing: border-box;
  padding: 2%;
}

.registration-main h2{
  margin: 0;
  margin-bottom: 2%;
  font-size: 2.5em;
  text-align: center;
}


.form-input{
  position: relative;
  margin: auto;
  margin-bottom: 5%;
  width: 100%;
  height: 20%;
}
.pasword-title{
  position: relative;
  margin: auto;
  margin-bottom: 5%;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.pasword-title p:nth-child(2n){
  color: #7a7a7a;
  cursor: pointer;
}

.submit-btn{
  cursor: pointer;
  width: 50%;
  font-size: 1.1em;
  margin: auto;
  height: 10%;
  background-color: orangered;
  color: #000;
}
.form-input{
  margin: auto;
  display: flex;
  width: 60%;
  flex-direction: column;
  color: #fcfcfc;
  justify-content: space-around;
}
.form-input p{
  font-size: 1.5em;

}

.registration-main input{
  border: 2px solid #7070709c;
  color: #fcfcfc;
  border-radius: 10px;
  cursor:auto;
  box-sizing: border-box;
  padding: 2%;
  height: 50%;
  width: 100%;
  font-size: 1.1em;
}

.registration-main input:focus{
  outline: none;
}

.form-close-btn{
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 5%;
  width: 10%;
  height: 10%;
}

.form-close-first-img{
  position: absolute;
  left: -30%;
  bottom: 0%;
  width: 50%;
  height: 60%;

}

.form-close-second-img{
  position: absolute;
  top: 15%;
  right: -40%;
  width: 50%;
  height: 70%;
}
.error-form-message{
 color: red;
 height: 10%;
 width: 100%;
 text-align: center;
}



form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}



.profile-section{
  width: 15%;
  height: 70%;
  display: flex;

  align-items: center;
}

.user_profile_menu{
  width: 50%;
}
.user-pfp{
  border-radius: 50%;
  width: 30%;
  height: 100%;
}

.user_profile_menu{
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  gap: 2%;
  font-size: 1.3em;
  align-items: center;
  position: relative;
}
.user_profile_menu p{
  overflow: hidden;
}
.user_profile_menu span{
transition: all 0.3s ease-in;
width: 20%;

height: 60%;
}

.user_profile_menu span img{
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  height: 100%;
}

.rotated{
  rotate: 180deg;
}


.user_profile_menu__main{
  z-index: 990900;
  border-radius: 10px;
  background-color: #252425;
  transition: all 0.1s ease ;
  height: 0px;
  width: 100%;
  top:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
}
.profile-inactive{
  height: 200px;
  transition: all 0.2s  ;
}

.user_profile_menu__main_item{
  width: 100%;
  height: 33.3%;
  margin: 0;
  transition:all 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.user_profile_menu__main_item:hover{
  background-color: #555555;
}
.user_profile_menu__main_item p{
  width: 75%;
  height: auto;
  font-size: 0.7em;
  overflow: hidden;
}

.user_profile_menu__main_item img{
  width: 20%;
  height: 100%;
}

.form-auth{
display: flex;
flex-direction: row;
justify-content: space-between;
height: 15%;
}
.social-auth{
  width: 40%;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #7070709c;
}
.social-auth:hover{
  border: 2px solid #c2c2c29c;
}
.social-auth img{
  width: 60%;
  height: 60%;
}







.UserPageLibrary-main{
  width: 100%;
  height: auto;
}

.UserPageLibrary-header{
  position: relative;
  width: 100%;
  height: 350px;
}

.header-bg{
  width: 100%;
  z-index: 1;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.UserPageLibrary-pfp{
  z-index: 99;
  position: absolute;
  top: 35%;
  left: 10%;
  width: auto;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
}
.UserPageLibrary-pfp img{
  width: 200px;
  height: 100%;
  border-radius: 50%;
}
.UserPageLibrary-pfp h2{
  font-size: 4em;
}


.tab{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.tab img{
  width: 10%;
  height: 100%;
}


.userPage-tabs{
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  height: auto;
}





.collection-create{
top: 0;
position: fixed;
width: 100%;
opacity: 1;
transition: all 0.5s ease-in;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
z-index: 9999;
background-color: #00000086;
}
.collection-create-main h2{
  font-size: 2em;
  margin-top: 0;
}
.collection-create-main{
  box-sizing: border-box;
  padding: 2%;
  position: relative;
  width: 30%;
  overflow-x: hidden;
  height: 70%;
  background-color: #252425;
  border-radius: 20px;
  overflow-y: auto;
}
.collection-create-btn{
  margin: auto;
  color: #000;
  font-size: 1em;
  cursor: pointer;
  margin-bottom: 5%;
  width: 100%;
  height: 10%;
}
.collection-create-avalible{
  width: 100%;
  height: 75%;
  overflow-y: auto;
}

.collection-create-avalible-item{
  width: 100%;
  cursor: pointer;
  height: 130px;
  margin-bottom: 10%;
  transition: all 0.1s ease;
  display: flex;
  flex-direction: row;
}
.collection-create-avalible-item:hover{
  padding: 5%;
  background-color: #ffffff4f;
}
.collection-create-avalible-item_info{
  width: 80%;
  height: 100%;
}
.collection-create-avalible-item_info p:nth-child(2n){
  color:#c2c2c29c;
}
.collection-create-avalible-item img{
  border-radius: 10px;
  width: 25%;
  height: 100%;
}
.no-preview{
  border-radius: 10px;
  width: 25%;
  height: 100%;
  border: 5px dashed orangered;
  font-weight: bold;
  color: orangered;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.no-preview img{
  width: 40%;
  height: 40%;
}
.colleciton-hidden{
  display: none;
  opacity: 0;
  transition: all 0.5s ease;
}



.notification-panel {
  position: fixed;
  z-index: 99999999;
  transition: all 0.6s ease;
  top: -20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:30%;
  height: auto;
  background-color: rgb(51, 51, 51);
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0.5%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  color:fff;
  align-items: center;
  font-size: 1.2em;
}

.visible-notification {
 top: 10%;
}




.notification-panel img{
  width: 20%;
  height: 40px;
  z-index: 99999;
  float: left;
}
.notification-panel p{
  height: 100%;
  float: right;
  font-weight: lighter;
  z-index: 99999;
  color: inherit !important;
}



.user-collection_items{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  z-index: 998;
}


.user-collection_item{
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 20%;
  height: 250px;
}
.user-collection_item:hover{
  transform: scale(1.05);
}

.user-collection_item img{
  width: 100%;
  height: 60%;
  border-radius: 10px;
  z-index: 999;
}
.user-collection_item .no-preview{
  width: 100%;
  height: 60%;
  border-radius: 10px;
}
.user-collection_item_info{
  width: 100%;
  font-size: 0.9em;
  height: 35%;
  overflow-x: hidden;
  
}
.user-collection_item_info h2{
  margin-bottom: 2% !important;
}



.dlt-confirm-main{
  position: fixed;
  top: 0;
  transition: all 0.1s ease;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000009d;
  opacity: 1;
}
.dlt-confirm-main-item{
  transition: all 0.1s ease;
  width: 40%;
  border-radius: 10px;
  height: auto;
  background-color: #252425;
  font-size: 1.6em;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  padding: 1%;
  flex-direction: column;
  
}
.dlt-confirm-main-item p{
  height: 70px;
}

.dlt-confirm-main-item-btns{
  display: flex;
   justify-content: center;
  align-items: center;
  gap: 5%;
  width: 100%;
}
.dlt-confirm-main-item-btns button{
  font-size: 0.8em;
  padding: 1%;
  font-weight: lighter !important;
  background-color: #fff;
  color: #000;
}

.dlt-hidden{
  z-index: -1;
  opacity: 0;
}




.registration-setup-block{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registration-setup-block-main{
  width: 40%;
  height: 80%;
  border-radius: 10px;  
  background-color: #252425;
  box-sizing: border-box;
  padding: 1%;
  position: relative;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-text{
  position: relative;
  width: 100%;
  height: 30%;
  overflow: hidden;
  box-sizing: border-box;

}

.text-step{
  transition: all 1s ease;
  font-size: 2.5em;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -100%;
  opacity: 0;
}

.active-step{
  bottom: 50%;
  opacity: 1;
}

.introudece-disabled{
  display: none;
}
.account_setup__main{
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1%;
  background-color: #252425;
  animation: fadeIn 0.4s;
  
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.account_setup__main-image-section{
  width: 100%;
  height: auto;
  border-bottom: 2px solid #575757;
  padding-bottom: 5%;

}


.dragable-image-setup{
  border:2px dashed orangered;
  margin-Top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15%;
  color: orangered;
  font-size: 1.5em;
  font-weight: bold;
  height: 200px;
  
}
.image-preview-setup{
  border-radius: 10px;
  width: 50%;
  height: 100%;
}
.set-another-image{
  background-color: #000;
  color: #fff;
  border-radius: 15px;
  padding: 1%;
  width: 30%;
  height: 50%;
}


.account_setup__main-about-section{
  width: 100%;
  height: auto;
  border-bottom: 2px solid #575757;
  padding-bottom: 5%;
}

.account_setup__main-about-section textarea{
  width: 100%;
  border-radius: 10px;
  background:none;
  border-bottom: 2px solid #444444;
  height: 200px;
  font-size: 1.4em;
  color: #fcfcfcc2;
}

.account_setup__main-genres{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.account_setup__main-genre{
  cursor: pointer;
  width:30%;
  border-radius: 10px;
  margin-bottom: 10%;
  height: auto;
}
.active-genre{
  border: 2px solid orangered;
}
.account_setup__main-genre p{
  font-size: 1.2em;
  z-index: 99;
  width: 100%;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: center;
  font-family: blazma-regular;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}


.account_setup__main-genre img{
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;

}

.account_setup_btn{
  width: 30%;
  height: auto;
  padding: 3%;
  background-color: #fff;
  color: #000;
  float: right;
  margin-top: 5%;
}


.setup-status-complete{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  box-sizing: border-box;
  padding: 5%;
  font-size: 2.5em;
  text-align: center;
  z-index: -1;
  transition: all 0.5s ease;
  background-color: #252425;
  display: flex;
  align-items: center;
  justify-content: center;  
}




.status-complete{
  opacity: 1;
  z-index: 9999;
}
.prefer-disabled{
  display: none;
}




.Page_info_wrapper{
  width: 100%;
  box-sizing: border-box;
  padding-top: 7%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.page_info__main{
  box-sizing: border-box;
  width: 60%;
  height: auto;
}
.page_info__main h2{
  font-size: 2.5em;
}
.page_info__premium{
  width: 30%;
  height: auto;
  max-height: 700px;
  border-radius: 10px;
  border: 2px solid orangered;
}

.info_item{
  width: 60%;
  height: auto;
  margin-bottom: 10%;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info_item p{
  width: 50%;
  font-size: 1.1em;
}


.premium_banner{
  display: block;
  background-color: orangered;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100px;
  box-sizing: border-box;
  padding: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
}

.premium_banner p{
  font-size: 2em;
  color: #000;
}


.premium_banner img{
  width: 30%;
  height: 100%;
}

.page_info__premium_main{
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 2%;
}

.page_info__premium_main .info_item{
  width: 100%;
}
.info_item p:nth-child(2n){
  color: #c2c2c2a9
}



.subscribe-block{
  position: fixed;
  overflow-x: hidden;
  top: 0;
  width: 100%;
  transition: all 0.2s ease;
  height: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000bd;
  opacity: 0;
}

.subscribe-block_main{
  width: 45%;
  position: relative;
  height: 80%;
  background-color: #252425;
  box-sizing: border-box;
  padding: 2%;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;

}

.subscribe-title{
  width: auto;
  font-size: 1.3rem;
  width: 100%;
}
.subscribe-title span{
  color: orangered;
}
.subscribe-pros{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}
.subscribe-checks{
  width: 60%;
  height: auto;
}
.subscribe-pros img{
  display: block;
  width: 30%;
  height: 100%;
}
.subscribe-check{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
  font-weight: bold;
  font-size: 1.1em;
}
.subscribe-infoBtn{
  cursor: pointer;
  position: absolute;
  top: 2.5%;
  right: 15%;
  width: 50px;
  height: 50px;
}
ul {
  list-style-type: none; 
}

li {
  position: relative; 
  padding-left: 20px;
}

li::before {
  content: ''; 
  position: absolute; 
  top: 50%; 
  left: 0; 
  transform: translateY(-50%); 
  width: 10px; 
  height: 10px; 
  background-color: orangered; 
  border-radius: 50%; 
}


.subscribe-plans{
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subscribe-plan{
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid #6e6e6e;
  box-sizing: border-box;
    
}
.subscribe-plan:first-child{
  background: linear-gradient(to right, rgb(255, 83, 20), red);
  border: none;
}

.subscribe-plan p {
  font-size: 1.1em;
  color: #fcfcfc91;
}
.subscribe-plan h2 {
  font-size: 2em;
  margin: 0;
}
.subscribe-plan p:nth-child(3n){
  font-style: italic;
  margin-top: 15%;
}


.choose-subscribe{
  display: block;
  width: 50%;
  font-size: 1.1em;
  margin-top: 5% !important;
  color: #ffffff;
  background: linear-gradient(to right, rgb(255, 83, 20), red);
  margin: auto;
  height: 70px;
}

.restore-premium{
  width: 100%;
  margin-top: 5%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;

}

.subscribe-active{
  opacity: 1;
  z-index: 99999;
}


.main-video-section{
  width: 100%;
  height: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
}

.video-main-container{
  width: 100%;
  height: 650px;
  margin-top: 5%;
}

.video-tabs{
  width: 30%;
  margin: auto;
  height: 50px;
}


.video-tab{
  width: 50%;
  height: 10%;

}
.video-tabs button{
  width: 50%;
  border-radius: 0;
  height: 100%;
  transition: all 0.2s ease;
  background-color:#474747;
}
.video-tabs button:first-child{
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.video-tabs button:last-child{
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
.tab-active{
  background-color: orangered !important;
}

.tab-video-content{
  width: 100%;
  height: 500px;
  transition: all 0.2s ease;
}
.tab-video-content h2{
  font-size: 2.5em;
}
.episodes-list{
  width: 100%;
  height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  background-color:#1b1b1b ;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
}


.episode-item {
  cursor: pointer;
  width:  15%;
  position: relative;
  border-radius: 10px;
  height: 45%;
  background-color: #000;
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.episode-item img{
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 10px;
}
.episode-item p{
  z-index: 999;
}

.setting-list{
  width: 100%;
  min-height: 400px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1%;
  background-color:#1b1b1b ;
  display: flex;
  flex-direction: column;
}
.active-episode{
  border: 2px solid orangered;
}

.setting-item{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
  height: 50px;
  margin-bottom: 2%;
}
.setting-item p{
  font-size: 1.1em;
}

.setting-option{
  cursor: pointer;
  height: 100%;
  min-width: 5%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: #444444;
  box-sizing: border-box;
  padding: 1%;
}



.active-setting{
  background-color: orangered;
}

.main-player{
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}



.mobile-menu {
  position: fixed;
  cursor: pointer;
  top: 5%;
  left: 5%;
  height: 40px;
  transition: all 0.2s ease;
  width: 15%;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  z-index: 999992;
  display: none;
  transition: all 0.2s ease;
  justify-content: space-between;
}

.mobile-menu span {
  display: block;
  width: 100%;
  height: 15%;
  transition: all 0.2s ease;
  background-color: #fff;
  border-radius: 30px;
 
}

.mobile-menu.mobile-open span:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
  background-color: #000;

}

.mobile-menu.mobile-open span:nth-child(2) {
  opacity: 0;
}

.mobile-menu.mobile-open span:nth-child(3) {
  background-color: #000;
  transform: translateY(-28px) rotate(-45deg);

}

.show-more_btn{
  display: block;
  width: 30%;
  margin: auto;
  height: 70px;
  font-size: 1.1em;
  color: #000;
}



.mobileMenu_main{
  position: fixed;
  top: 0;
  box-sizing: border-box;
  padding: 5%;
  left: 0;
  z-index: 999991;
  height: 30%;
  transform: translateY(0);
  background-color: #fff;
  width: 100%;
  display: flex;
flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.2s ease;
}
.logo-mobile{
  display: block !important;
  color: #000000 !important;
  font-size: 3.5em;

}
.mobileMenu_main p{
  height: auto;
  color: #000 !important;
  font-size: 1.4em;
}
.mobileMenu_main nav{
  display: flex;
  flex-direction: column;
  height:100%;  
  width: 100%;
}

.disabled-menu{
  transition: all 0.2s ease;
  transform: translateX(-100%);
}








@media screen and (min-width:2000px) {
 
  .Main_Content{
    padding-left: 15%;
    padding-right: 15%;
  }
  header{
    padding-left: 15%;
    padding-right: 15%;
  }
  .announcment_content{
    padding-left: 5%;
  }
  .News_wrapper__main{
    padding-left: 20%;
    padding-right: 20%;
  }
  .Collection_wrapper{
    box-sizing: border-box;
    padding-left: 10%;
    margin-top: 5%;
    padding-right: 10%;
  }
  .account_setup__main-genre{
    width: 20%;
  }
  .account_setup__main-genres{
    gap: 5%;
  }
  .Page_info_wrapper{
    padding-left: 15%;
    padding-right: 15%;
  }
  .userPage-tabs{
    padding-left: 10%;
    padding-right: 10%;
  }
  .main-video-section{
    padding-left: 10%;
    padding-right: 10%;
  }
  .video-main-container{
    height: 800px;
  }
  .anime-gallery{
    padding-left: 10%;
    padding-right: 10%;
    height: 400px;
  }
  .anime_main-additional{
    padding-left: 10%;
    padding-right: 10%;
  }
  .registration-setup-block-main{
    padding-left: 2%;
    padding-right: 2%;
  }
}


@media only screen and (min-width: 2560px) {
  .Main_Content{
    padding-left: 20%;
    padding-right: 20%;
  }
  header{
    padding-left: 20%;
    padding-right: 20%;
  }
  .announcment_content{
    padding-left: 10%;
  }
  .News_wrapper__main{
    padding-left: 15%;
    padding-right: 15%;
  }
  .Collection_wrapper{
    box-sizing: border-box;
    padding-left: 15%;
    margin-top: 10%; 
    padding-right: 15%;
  }
  .Page_info_wrapper{
    padding-left: 20%;
    padding-right: 20%;
  }
  .userPage-tabs{
    padding-left: 15%;
    padding-right: 15%;
  }
  .main-video-section{
    padding-left: 15%;
    padding-right: 15%;
  }
  .video-main-container{
    height: 850px;
  }
  .anime-gallery{
    padding-left: 15%;
    padding-right: 15%;
    height: 450px; 
  }
  .anime_main-additional{
    padding-left: 15%;
    padding-right: 15%;
  }
  .registration-setup-block-main{
    padding-left: 7%; 
    padding-right: 7%; 
  }
}







@media screen and (max-width:1600px) {

  html{
    font-size: 0.9em;
    overflow-x: hidden !important;
  }
  
  .NewsPage_main {
    width: 60%;
  }
  
}
.utf-bookmark{
  font-size: 1.2em;
  margin-right: 10%;
}
.show-more_btn{
  width: 35%;
}

@media screen and (max-width:1400px) {

  html{
    font-size: 0.8em;
  }
 .trending_section {
  height: 250px;
 }
  .utf-bookmark{
    font-size: 1.6em;
    margin-right: 10%;
  }
  .NewsPage_main {
    width: 60%;
  }
  .video-main-container{
    height: 600px;
  }

  .announcment_content{
    width: 70%;
  }
  .user_profile_menu__main{
    width: 150%;
  }
  header{
    padding-left: 2%;
    padding-right: 2%;
  }

  .user-pfp {
    width: 40%;
  }
  .News_wrapper__banner_text{
    width: 60%;
  }
  .show-more_btn{
    width: 40%;
  }
}



@media screen and (max-width:1200px) {
  .Main_Content_Upcoming_wrapper{
    font-size: 0.8em;
  }
  .UpcomingTitle_item{
    height: 400px;
  }
  .anime_main-info_image{
    height: 400px;
  }
  .trending{
    margin-bottom: 0;
    height: 380px !important;
  }
  .NewsPage_main{
    width: 70%; 
  }
  .recomendation{
    top: 0;
  }.recomendation-btn{
    top: 50%;
  }
  .main-video-section{
    margin-top: 10%;
  }
  .rating_firstPlace h2{
    font-size: 3em;
  }
  .rating_content{
    justify-content: inherit;
  }

.Main_Content_Top__rating h2{
  font-size: 3em;
}
 
  .anime_main-info_title__buttons button span:last-child{
    font-size: 1.5em;
  }
  .video-tabs{
    height: 40px; 
  }
  .video-main-container{
    height: 550px;
  }
  .NewsPage_main h2{
    width: 70%;
  }
  .news-timeStamp img{
    width: 15%;
  }
  .best-collection{
    height: 250px;
  }
  .anime-gallery{
    height: 250px;
  }
  .Details{
    width: 25%;
  }
  .Description{
    width: 70%;
  }
  .characters-item{
    height: 300px;
  }
  .tab-video-content{
    height: 350px;
  }
  .page_video-banner button{
    width: 10%;
  }
  .CollectionPage_main{
    width: 50% ;
  }
  .collection-create-main{
    width: 50%;
  }
  .collection-new-main{
    width: 45%;
  }
  .subscribe-plan h2{
    font-size: 1.3em;
  }
  .subscribe-plan p{
    font-size: 1em;
  }
  .reg{
    gap: 0%;
  }
  .buttons{
    width: 25%;
  }
  .search-form{
    width: 25%;
  }
  .registration-main{
    width: 50%;
  }
  .Main_Content_Collections{
    height: 320px;
  }

  .Page_info_wrapper{
    padding-top: 15%;
  }

}


@media screen and (max-width:950px) {
  html{
    font-size: 0.7em;
  }
  header nav{
    width: 35%;
  }
  .trending{
    margin-bottom: 0;
    height: 300px !important;
  }
  .collection-create-main{
    width: 90vw;
  }
  .overlay-images{
    border-radius: 15px;
  }
  .Main_Content_Upcoming_wrapper{
    font-size: 0.8em;
  }
  .UpcomingTitle_item{
    height: 320px;
    
  }
  .announcment_content{
    width: 75%;
  }
  .anime_main-info_title__buttons button svg{
    width: 5%;
  }
  .utf-bookmark_anime-page{
    width: 10%;
  }
  .utf-bookmark{
    font-size: 1.5em;
    margin-right: 15%;
  }
  .account_setup__main-genre img{
    height: 100px;
  }
  .dlt-confirm-main-item{
    width: 90vw;
  }
  .dlt-confirm-main-item button{
    width: 45%;
  }

  .subscribe-infoBtn{
    right:25%;
    width: 10%;
  }
  .subscribe-block_main{
    width: 70%;
  }
  .tab{
    flex-direction: column;
  }
  .tab img{
    height: 35%;
    width: 60%;
  }
  .subscribe-plans{
    height: 200px;
  }
 .show-more_btn{
  width: 45%;
 }
  .carousel-button{
    font-size: 3em;
  }
  .Main_Content_item_text{
    display: flex;
    justify-content: end;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .Main_Content_Collections{
    height: 270px;
  }
  .Main_Content_item img{
    border-radius: 15px;
  }
  .popular-item{
    height: 150px !important;
  }
  .Main_Content_item_text h3{
    font-size: 0.7em;
  }
  .Main_Content_item_info{
    font-size: 0.6em;
  }
  .Main_Content_item{
    border-radius: 15px;
  }
  .Main_Content_item{
    height: 220px;
  }
  .catalog_title{
    margin-top: 15%;
  }
  .item-catalog{
    height: 130px !important;
  }
  .catalog_content{
    gap: 5%;
  }
  .catalog_sidebar{
    width: 30%;
  }
  .News_wrapper__content_item{
    height: 300px;
  }
  h1{
    font-size: 1.9em;
  }
  .News_wrapper__content{
    margin: 0;
  }
  .News_wrapper__content{
    width: 100%;
  }
  .News_wrapper__banner_text span{
    font-size: 2.4em;
  }
  .News_wrapper__banner{
    height: 500px;
  }
  .banner_wrapper{
    height: 600px;
  }
  .announcement_banner{
    height: 600px;
  }
  .best-collection{
    height: 200px;
  }
  .tabs{
    width: 100%;
  }
  .userPage-tabs{
    padding: 0;
  }
  .form-block-item{
    height: 80px;
  }
  .form-block-item-info{
    font-size: 0.7em;
  }
  .form-block{
    width: 200%;
  }
  .search-showed{
    z-index: 9999999999;
  }

  .page_video-banner{
    height: 300px;
  }

  .anime_main-info{
    margin: 0;
  }
  .anime_main-info_image{
    height: 300px;
    width: 40%;
  }
  .page_video-banner button:last-child{
    width: 7%;
  }
  .page_video-banner button{
    right: 15%;
    width: 14%;
  }
  .anime_main-info_title__buttons button:last-child{
    position: inherit;
    width: 100%;
  }
  .anime_main-info_title__buttons{
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .anime_main-info_title__buttons button{
    height: 40px;
    width: 100%;
  }
  .anime_main-info_title{
    height: auto;
  }
  .anime_main-info_title__buttons button{
    margin-bottom: 5%;
  }
  .anime_main-info{
    margin-bottom: 5%;
  }
  .main-video-section{
    padding: 0;
  }

  .video-main-container{
    height:450px ;
  }
  .tab-content{
    min-height: auto;
    padding: 0;
  }
  
  .characters-item{
    flex-shrink: 0;
  }
  .staff-role{
    width: 100%;
    text-align: center;
  }
  .rating-webSite{
    width: 30%;
  }
  .video-tabs{
    width: 45%;
  }

  .trailer-videoplayer{
    height: 60%;
  }
  .collection-new-main{
    width: 60%;
  }
  .collection-create-avalible-item{
    height: 100px;
  }
  .registration-main{
    width: 60%;
  }
  .registration-setup-block-main{
    width: 60%;
  }
  .CollectionPage_main {
    width: 70%;
    left: 15%;
  }
}

@media screen and (max-width:800px) {
  .logo-desktop{
    display: none;
  }
  header nav{
    width: 40%;
  }
  .trending_img{
    display: none !important;
  }
  .trending_section {
    width: 100% !important;
  }
 .trending {
  height: 300px !important;
 }
  
}
@media screen and (max-width:700px) {
  html{
    font-size: 0.7em;
  }
  .logo-desktop{
    display: block;
  }
.characters-main{
  height: 120px;
}
.indicator{
display: none;
}
.Main_Content_item_text h3{
  font-size: 0.6em !important;
}
  .overlay-images{
    border-radius: 10px;
  }
  .Main_Content_items{
    height: 150px;

  }
  .title_number{
    font-size: 1em;
  }
  .trending{
    height: auto !important;
  }
  .Main_Content_Upcoming_wrapper{
    font-size: 0.8em;
    flex-direction: column;
  }
  .UpcomingTitle_item{
    flex-direction: row;
    height: 70px ;
    width: 100%;
    margin-bottom: 10%;
    font-size: 1.2em;
  }
  .UpcomingTitle_item img{
    width: 30%;
    height: 100%;
  }
  .UpcomingTitle_Info{
    height: 100%;
    margin-left: 5%;
  }
  .utf-bookmark{
    font-size: 1.9em;
  }
  .title-tags{
    justify-content: center;
  }
  .title-tags span{
    width: 25%;
  }
  .Rate_wrapper{
    flex-direction: column;
    height: auto;
  }
  .Main_Content_Top{
    height: auto;
    margin-bottom: 20%;
  }
  .rating_content{
    width: 100%;
    height: 400px;
  }
  .rating_firstPlace h2{
    height: auto;
  }
  .rating_firstPlace{
    width: 100%;
    height: auto;

  }
  .forYou{
    height: auto !important;
  }
  .Main_Content_Top__rating{
    width: 100%;
  }
  .firstPlace_image{
    width: 80%;
    display: block;
    margin: auto;
    height: 300px;
    float: inherit;
  }
  .firstPlace_name{
    margin-top: 5%;
    float: left;
    height: 50px;
  }
  .rating{
    margin-top: 5%;
   height: 50px;
  }

  .show-more_btn{
    width: 100%;
    height: 50px;
  }
  .Main_Content{
    padding-left: 3%;
    padding-right: 3%;
  }
  .form-input{
    width: 80%;
  }
  .form-close-first-img{
    height: 50%;
    width: 40%;
  }
  .submit-btn{
    min-width: 40%;
    width: auto;
  }
  .registration-main h2{
    font-size: 1.5em;
  }
  

  .Main_Content_items{
    flex-wrap: wrap
  }
  .trend-item{
    width: 45% !important;
  }
  
  .Main_Content_Collections{
    height: auto !important;
    
  }
.Main_Content_Collections__items {
  height: auto !important;
  flex-wrap: wrap;
}
.main-collection-item{
  width: 100% !important;
  height: 200px;
}


  
  header nav{
    display: none;
  }
  .search-form{
    width: 40%;
  }
  .form-block{
    width: 100%;
  }
  .subscribe-pros{
    flex-direction: column;
  }
  .subscribe-checks{
    width: 100%;
  }
  .subscribe-pros img{
    width: 50%;
    margin: auto;
  }
  .subscribe-block_main{
    width: 100%;
    height: 70vh;
    box-sizing: inherit;
    padding: 2% !important;
  }
  .choose-subscribe{
    height: 50px;
  }
 

  .subscribe-plans{
    height: 150px;
  }

  .form-block-item{
    height: 70px;
  }
  .search-form img{
    width: 30% !important;
  }
  .form-block{
    height: 400px;
  }
  .logo-desktop{
    display: none;
  }
  .profile-section {
    width: 25%;
  }
  .search-form{
    width: 50%;
  }
  .mobile-menu{
    display: flex;
  }
  .Main_Content_item{
    border-radius: 10px !important;
  }
  .Main_Content_item img{
    border-radius: 10px !important;
  }
  .search-form{
    width: 100%;
    padding: 5%;
    height: 50px;
  }
  header{
    padding: 0;
    flex-wrap: wrap;
    height: auto;
  }
  .form-block-item{
    height: 100px;
  }
 
  .profile-section{
    width: 50%;
    margin: auto;
    height: 50px;
  }
  .announcment_content{
    width: 100%;
    margin-top: 10%;
    width: 90%;
  }
  .Main_Content_item_text{
    border-radius: 10px;
  }
  .announcement_buttons{
    margin-top: 5%;
    width: 100%;
  }
  .announcement_buttons button{
    width: 35%;
  }

  .Main_Content_item{
    height: auto;
    width: 20% !important;
  }
  .Main_Content_list{
    height:auto
  }
  .upper-score p{
    font-size: 0.8em;
  }
  .upper-score{
    border-radius: 0;
  }
  .carousel-button{
    font-size: 2em;
  }
  .Main_Content_Collections{
    height: 190px;
  }
  .Main_Content_Collections__item{
    border-radius: 5px;
  }
  .Main_Content_Collections__item h3{
    font-size: 1.5em;
  }
  .recomendation{
    top:0px;
  }
  .first-card{
    border-radius: 5px;
  }
  .first-card img{
    border-radius: 5px;
  
  }
  .second-card{
    border-radius: 5px;
  }
  .second-card img{
    border-radius: 5px;
  }
  .third-card{
    border-radius: 5px;
  }
  .third-card img{
    border-radius: 5px;
  }
  footer nav{
    width: 100%;
  }
  .user_profile_menu p:first-child{
    font-size: 1.6em;
    overflow: hidden;
  }
  .user_profile_menu p{
    font-size: 1.5em;
  }

  .user-collection_item{
    height: 200px;
  }
  .user-collection_item__dlt-btn{
    width: 40%;
  }
  .UserPageLibrary-pfp{
    height: 30%;
    width: 60%;
    margin: auto;
    top: 50%;
  }
  .sorting{
    display: none;
  }
 

  .catalog_main{
    flex-direction: column;
  }

  .catalog_sidebar{
    width: 90%;
    margin: auto;
  }
  .item-catalog{
    width: 30% !important;
    margin-bottom: 5%;
  }
  .catalog_item__title{
    font-size: 1.5em;
  }
  .catalog_title{
    margin-top: 50%;
    font-size: 2.8em;
  }
  .catalog_content{
    width: 100%;
    justify-content: space-around;
  }
 
  
.News_wrapper__banner_text{
  left: 0;
  width: 100%;
  z-index: 100;
}
.News_wrapper__banner_text button{
  width: 45%;
  font-size: 1.4em;
}

.News_wrapper__content_item{
  border-radius: 5px !important;
  width: 45%;
  height: 170px;
}
.News_wrapper__content_item img{
  border-radius: 5px !important;
  object-fit: cover;
}
.News_wrapper__content_text p{
  font-size: 1.1em;
}
.news-timeStamp{
  position: inherit;
  font-size: 1.5em;
}
.NewsPage_main h2{
  margin-top: 0;
}
.NewsPage_main{
  width: 90%;
}

.NewsPage_main_secion img{
  height: 200px;
}

.Collection_wrapper__main{
  justify-content: space-between;
}
.Main_Content_Collections__item{
  width: 45%;
  margin-bottom: 5%;
}
.Collection_wrapper{
  margin-top: 50%;
  padding: 0;
}
.Collection_wrapper__title button{
  width: 40%;
  height: 50px;
  font-size: 1.2em;
}
.collection-new-main{
  width: 100%;
  overflow-x: hidden;
}
.collection-new-main textarea{
  font-size: 1.5em;
}
.CollectionPage_main{
  width: 100%;
}
.CollectionPage_main{
  left: 0;
}
.collection-new-sumbit{
  font-size: 1.5em;
}
.CollectionPage_main h2:nth-child(2n){
  font-size: 3.5em;
}
.collection-rate{
  height: 10%;
}
.collection-rate p{
  font-size: 0.5em;
}
.main-collection-item{
  width: 30%;
}

.anime_main-info_image{
  width: 80%;
  margin: auto;
  height: 250px;
  transform: translateY(0);
}
.page_video-banner button:last-child{
  width: 10%;
}
.anime_main-info {
  flex-direction: column;
  width: 100%;
}

.page_video-banner button{
  width: 30%;
  font-size: 1.3em;
  right: 20%;
}
.anime_main-info_title{
  width: 95%;
  margin: auto;
}
.rating-webSite{
  font-size: 1.3em;
}
.video-tabs{
  font-size: 1.5em;
}
.episode-item{
  width: 25%;
}
.episodes-list{
  box-sizing: border-box;
  padding-left: 10%;
}
.video-main-container{
  height: 200px;
}
.anime-gallery{
  height: 200px;
}
.recomendation-btn{
  top: 50%;
}
.carousel-button{
  font-size: 2.4em;
  z-index: 100;
}
.anime_main-info_title__buttons{
  width: 70%;
  margin:5% auto;
  
}
.fullscreen-image-container{
  width: 90%;
  height: 50%;
}
.video-tabs{
  width: 60%;
}
.anime-gallery{
  height: 150px;
  width: 100%;
  margin-bottom: 10% !important;
}
.carousel-slide{
  width: 20vw;
}
.episode-item {
  height: 30%;
}
.Details{
  width: 40%;
}
.Description{
  width: 50%;
  float: right;
}
.player-controls {
  transform: translateY(60%) !important ;
}
.play-button{
  width: 10%;
  height: 40%;
}
.sound-tool{
  display: none;
}
.setting-option{
  min-width: 10%;
}
.Page_info_wrapper{
  padding-top: 40%;
  flex-direction: column;
}
.page_info__main{
  width: 100%;
}
.page_info__premium{
  width: 100%;
}
.buttons{
  width: 80%;
  height: 40px;
  margin-bottom: 1%;
}
.buttons img{
  width: 30%;
}
.button{
  width: 45%;
  height: 100%;
}
.registration-main{
  height:60%;
}
.form-close-btn{
  top: 0%;
}
.pasword-title p:nth-child(2n){
  float: right;
  font-size: 0.9em;
}
.social-auth{
  border-radius: 5px;
  
}

.social-auth img{
  width: 40%;
  height: 40%;
}
.registration-setup-block-main{
  width: 90%;
}
.account_setup_btn{
  font-size: 1.1em;
}
.Page_info_wrapper{
  font-size: 1.2em;
}
.info_item{
  width: 100%;
}
.announcement_title{
  font-size: 3em;
}

.popular-item{
  width: 30% !important;
}
.trend-item{
  height: 100%;
}
.special-items{
  height: 200px;
}
.swiper-button-next,
  .swiper-button-prev {
 font-size: 0.9em;
    width: 1%;
    height: 1px;
  }
.special-item{
  height: 130px !important;
}
.catalog_title h2{
  font-size: 2em;
}
.form-block-item-info{
  font-size: 1em;
}
}

