.line{
    display: block;
    z-index: 9999999;
    margin: auto;
    font-size: 50px;
}

body{
    overflow-y: hidden;
    animation: body-hidden 0.5s 5s forwards;
}

@keyframes body-hidden {
    0%{overflow-y: hidden;}
    100%{overflow-y: auto;}
}


.cherry {
    position: absolute;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation:cherry-fade 1s 3.7s forwards
}
@keyframes cherry-fade {
    0%{opacity: 1;}
    99%{opacity: 0;}
    100%{display: none};
}

.intro-line{
    z-index: 999;
    transition: all 0.5s ease;
    width: 0%;
    margin: auto;
    height: 12px;
    position: absolute;
    top: 49.5%; 
    left: 0; 
    right: 0; 
    rotate: 0deg;
    background-color:orangered;
    border-radius: 30px;
    animation: span-animation 2.4s 1s forwards; 
}
.intro-title{
    font-family:Kurayami;
    z-index: 99999;
    width: 100%;
    color: black;
    text-align: center;
    margin: auto;
    height:auto;
    position: absolute;
    font-size: 7em;
    top: 35%; 
    left: 0; 
    opacity: 0;
    right: 0; 
    rotate: 0deg;
    animation:fadeIn 1s forwards, text-fade 0.5s 2s forwards;
}

@keyframes text-fade {
 0%{
    opacity: 1;
 }   
 100%{
    opacity: 0;
 }
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
@keyframes span-animation {
    0% {
        width: 0;
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        width: 100%;
        transform: rotate(0deg);
        opacity: 1;
    }
    55% {
        width: 100%;
        transform: rotate(0deg); 
        opacity: 1;
    }
    90% {
        width: 100%;
        transform: rotate(90deg);
        opacity: 1;
    }
    95% {
        width: 100%;
        transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        width: 100%;
        transform: rotate(90deg);
        opacity: 0;
    }
}

.block1{
    width: 50%;
    height: 100vh;
    background-color: #fff;
    animation: borderRight 2s 3.1s forwards; 
}
@keyframes borderRight {
    0%{ border-right:6px solid orangered;
        transform: translateX(0%);}
    10%{ transform: translateX(0%);}
    50%{ border-right:6px solid orangered;}
    100%{ border-right:6px solid orangered;
        transform: translateX(-100%);}}
    

.block2{
    width: 50%;
    height: 100vh;
     background-color: #fff;
    animation: borderLeft 2s 3.1s forwards; 
}
@keyframes borderLeft {
  
    0%{ border-left:6px solid orangered;
        transform: translateX(0%);}
    10%{ transform: translateX(0%);}
    50%{ border-left:6px solid orangered;}
    100%{ border-left:6px solid orangered;
    transform: translateX(100%);}
}

.block-wrapper{
    display: flex;
    flex-direction: row;
    z-index: 9999999;
  
}
.intro-wrapper{
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    animation: intro 1s 5s forwards;
}

@keyframes intro {
    0%{display: block;}
    100%{
        display: none;
    }
}   